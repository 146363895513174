import React from "react";

import { Container, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Article from "../components/Article";
import { articles } from "../data/articles";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
  blogsContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  blogTitle: {
    paddingBottom: theme.spacing(5),
    fontWeight: 800,
  },
}));

export default function Blogs() {
  const classes = useStyles();
  return (
    <div>
    <Helmet>
    <title>WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Blogs </title>    
    <meta property="title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Blogs" />
    <meta name="description" content="WashOn Laundry's Blog: Dive into insightful articles covering laundry tips, industry news, and lifestyle advice. Elevate your laundry experience!" />
     <meta name="keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Blogs" />
    <meta property="og:description" content="WashOn Laundry's Blog: Dive into insightful articles covering laundry tips, industry news, and lifestyle advice. Elevate your laundry experience!" />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:url" content="https://www.washon.ae/blogs" />
    <meta property="og:site_name" content="WashOn Laundry Service: Blogs" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Blogs" />
    <meta name="twitter:description" content="WashOn Laundry's Blogs: Dive into insightful articles covering laundry tips, industry news, and lifestyle advice. Elevate your laundry experience!"/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
        {/* Add other head content specific to the about page */}
        </Helmet>
      {/* <div
        className="header-image"
        style={{ position: "absolute", top: "0", zIndex: "-1" }}
      >
        <img
          alt="Page Background"
          src={
            window.innerWidth > 1440
              ? largeBackgroundImg
              : window.innerWidth > 768
              ? backgroundImg
              : backgroundMobileImg
          }
        />
      </div>
      <div>
        <div className="heading">Blogs</div>
      </div> */}
      <Container maxWidth="lg" className={classes.blogsContainer}>
        <h1 style={{ marginBottom: 20, fontSize: 35, color: "rgb(30, 30, 93)"}}>
          Laundry Services Dubai
        </h1>
        {/* <Typography className={classes.blogTitle} variant="h1" color="rgb(30, 30, 93)" fontSize="10" > */}
          {/* WashOn Laundry Services Dubai: Blogs */}
        {/* </Typography> */}
        {/* to add more articles to the blog, go to src/data/articles.js and add accordingly */}
        <Grid container spacing={3}>
          {articles.map((a, i) => (
            <Grid item xs={12} sm={6} md={4}>
              <Link to={`/blogs/${a.link}`}>
                <Article
                  title={a.title}
                  image={a.image}
                  subtitle={a.subtitle}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
