import React from "react";
import washfoldIcon from "../images/wash-fold-service-icon.svg";
import washPressIcon from "../images/wash-press-service-icon.svg";
import dryCleaningIcon from "../images/dry-cleaning-service-icon.svg";
import steamPressIcon from "../images/steam-press-service-icon.svg";
import shoeCleaningIcon from "../images/shoe-cleaning-service-icon.svg";
import curtainCleaningIcon from "../images/curtain-cleaning-service-icon.svg";
import carpetCleaningIcon from "../images/carpet-cleaning-service-icon.svg";
import bagCleaningIcon from "../images/bag-cleaning-icon.svg";
import alterationIcon from "../images/alteration-service-icon.svg";
import washfoldImg from "../images/wash-fold-service.png";
import shoeCareImg from "../images/shoe-care-service.png";
import curtainCleaningImg from "../images/curtain-cleaning-service.png";
import carpetCleaningImg from "../images/carpet-cleaning-service.png";
import dryCleaningImg from "../images/dry-cleaning-service.png";
import steamPressImg from "../images/steam-press-service.webp";
import washPressImg from "../images/wash-and-press-service.webp";
import bagCleaningImg from "../images/bag-cleaning.webp";
import alterationImg from "../images/alteration-service.webp";
import strollerCleaningIcon from "../images/stroller-cleaning-service-icon.png";
import strollerCleaningImg from "../images/stroller-cleaning-service.webp";

export const services = [

  {
    id: 1,
    link: "drycleaning-service",
    title: "DryCleaning Service",
    subtitle:
      "All your laundry will be perfectly drycleaned, ironed and folded or put on hangers",
    icon:dryCleaningIcon,
    image:
    dryCleaningImg,
    html: (
      <div className="content-container-services content-container-service"  >
      <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>DryCleaning Service in Dubai</h2>
      <p style={{ fontSize: 18 }}>
        At WashOn, your dry cleaning items undergo a meticulous process, 
        utilizing advanced techniques and eco-friendly solvents for a thorough clean. 
        We provide free pickup and delivery in Dubai, ensuring your garments 
        receive the care they deserve without disrupting your busy schedule.
        </p>
        <h3 style={{ fontSize: 22 }}>Professional WashOn Dry Cleaner</h3>
        <ol style={{ fontSize: 18 }}>
            <li>
            Assessment: We carefully inspect each item at our washon facility to determine the 
            appropriate cleaning method.
            </li>
            <li>
            Cleaning: Utilizing advanced techniques and eco-friendly solvents, 
            we remove stains and odors while preserving the fabric's integrity.
            </li>
            <li>
            Finishing: Your garments are expertly pressed and inspected before 
            being returned to you, ensuring they look and feel their best.
            </li>
          </ol>
      </div>
    ),
  },
  {
    id: 2,
    link: "shoe-cleaning",
    title: "Shoe Cleaning",
    subtitle:
      "Send us your shoes for meticulous care and a polished, refreshed look",
    icon:shoeCleaningIcon,
    image:
      shoeCareImg,
      html: (
        <div className="content-container-services content-container-service"  >
        <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Shoe Cleaning Service in Dubai</h2>
        <p style={{ fontSize: 18 }}>
        At WashOn, your shoes receive expert care through a meticulous process. WashOn team assess each pair, 
        apply specialized cleaning techniques, and finish with conditioning for a pristine look. 
        We provide free pickup and delivery in Dubai, ensuring your footwear looks its best without any hassle.
          </p>
          <h3 style={{ fontSize: 22 }}>Professional Shoe Cleaning</h3>
          <ol style={{ fontSize: 18 }}>
              <li>
              Inspection: Each pair is carefully examined to identify any specific cleaning requirements.
              </li>
              <li>
              Cleaning: We utilize specialized techniques and solutions to remove dirt, stains, and odors.
              </li>
              <li>
              Conditioning: Your shoes are treated with conditioning agents to restore their appearance 
              and prolong their lifespan.
              </li>
            </ol>
        </div>
      ),
  },
  {
    id: 3,
    link: "wash-and-fold",
    title: "Wash and Fold",
    subtitle: "Fill your bags with clothes that requirs washing. All your laundry will be perfectly washed and folded",
    icon:washfoldIcon,
    image:
      washfoldImg,
      html: (
        <div className="content-container-services content-container-service"  >
        <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Wash and Fold Service in Dubai</h2>
        <p style={{ fontSize: 18 }}>
        At WashOn, our wash and fold process is designed for ultimate convenience. Simply schedule a pickup in Dubai, 
        and our team will collect your laundry. We provide free pickup and delivery. We then meticulously wash fold your 
        items before delivering them back to you, all at no extra cost.
          </p>
          <h3 style={{ fontSize: 22 }}>Professional Cloth Washing</h3>
          <ol style={{ fontSize: 18 }}>
              <li>
              Pickup: Schedule a convenient pickup time in Dubai with our washon app.              
              </li>
              <li>
              Washing: Our team thoroughly washes and dries your laundry.
              </li>
              <li>
              Folding: Your items are meticulously folded and delivered back to you.
              </li>
            </ol>
        </div>
      ),
  },
  {
    id: 4,
    link: "carpet-cleaning",
    title: "Carpet Cleaning",
    subtitle:
      "Your carpet is cleaned and neatly packed",
    icon:carpetCleaningIcon,
    image:
      carpetCleaningImg,
      html: (
        <div className="content-container-services content-container-service"  >
        <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Carpet Cleaning in Dubai</h2>
        <p style={{ fontSize: 18 }}>
        Experience hassle-free carpet cleaning with WashOn in Dubai. 
        Schedule a pickup, and our team will collect your carpets. 
        We employ advanced techniques to deep clean and sanitize your carpets, 
      ensuring a fresh and hygienic home. Enjoy free pickup and delivery 
      for this. 
          </p>
          <h3 style={{ fontSize: 22 }}>Professional Carpet Cleaning</h3>
          <ol style={{ fontSize: 18 }}>
              <li>
              Pickup: Schedule a convenient time for us to collect your carpets.              
              </li>
              <li>
              Cleaning: Our team utilizes advanced techniques to deep clean and sanitize your carpets thoroughly.
              </li>
              <li>
              Delivery: We'll deliver your freshly cleaned carpets back to you.              </li>
            </ol>
        </div>
      ),
  },
  {
    id: 5,
    link: "curtain-cleaning",
    title: "Curtain Cleaning",
    subtitle:
      "Your curtain is cleaned and neatly folded",
    icon:curtainCleaningIcon,
    image:
      curtainCleaningImg,
      html: (
        <div className="content-container-services content-container-service"  >
        <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Curtain Cleaning Service in Dubai</h2>
        <p style={{ fontSize: 18 }}>
        At WashOn, we simplify curtain cleaning in Dubai. Enjoy free pickup and delivery for your convenience. 
        Our expert team employs advanced techniques to deep clean and revitalize your curtain, ensuring a fresh 
        and hygienic living space. Experience hassle-free curtain cleaning with WashOn today.
          </p>
          <h3 style={{ fontSize: 22 }}>Blackout Curtain cleaning with washon</h3>
          <ol style={{ fontSize: 18 }}>
              <li>
              Assessment: We inspect the blackout curtains to determine the fabric type and any specific cleaning requirements.              
              </li>
              <li>
              Cleaning: Using specialized techniques, we remove dirt, dust, and stains from the curtains while preserving their blackout properties.
              </li>
              <li>
              Finishing: After thorough cleaning, we ensure the curtains are dried and pressed to perfection before returning them to you.
              </li>
            </ol>
        </div>
      ),
  },
  {
    id: 6,
    link: "steam-press",
    title: "Steam Pressing service",
    subtitle:
      "All your laundry will be perfectly iron and folded or put on hangers",
    icon:steamPressIcon,
    image:
      steamPressImg,
      html: (
        <div className="content-container-services content-container-service"  >
        <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Steam Pressing Service in Dubai</h2>
        <p style={{ fontSize: 18 }}>
        Experience impeccable steam pressing with WashOn in Dubai, coupled with free pickup and delivery.
         Once collected, your garments undergo meticulous steam pressing to remove wrinkles and ensure a polished finish. 
         Enjoy the convenience of having your items returned to you flawlessly pressed, without any extra effort on your part.
          </p>
          <h3 style={{ fontSize: 22 }}>Professional Cloth Washing</h3>
          <ol style={{ fontSize: 18 }}>
              <li>
              Collection: Schedule a pickup time for your garments in Dubai, at no additional cost.             
              </li>
              <li>
              Steam Pressing: Our expert team uses advanced steam pressing techniques to remove wrinkles and creases, ensuring a pristine appearance.
              </li>
              <li>
              Delivery: Your garments are promptly delivered back to you, perfectly pressed and ready to wear.
              </li>
            </ol>
        </div>
      ),
  },
  {
    id: 7,
    link: "wash-and-press",
    title: "Wash and Press",
    subtitle:
      "Fill your bags with clothes that requirs washing and pressing. All your laundry will be perfectly washed ironed and folded or put on hangers",
    icon:washPressIcon,
    image:
    washPressImg,
    html: (
      <div className="content-container-services content-container-service"  >
      <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Wash and Press Service in Dubai</h2>
      <p style={{ fontSize: 18 }}>
      Experience effortless wash and press services with WashOn in Dubai, complete with complimentary pickup and delivery. 
      Schedule a pickup, and our team will collect your garments. T
      hey undergo meticulous washing and professional pressing, ensuring a fresh, crisp finish. 
      Enjoy the convenience of having your items returned to you immaculately cleaned and pressed.
        </p>
        <h3 style={{ fontSize: 22 }}>Professional Wash and Press Service</h3>
        <ol style={{ fontSize: 18 }}>
            <li>
            Pickup: Schedule a convenient pickup time in Dubai with our washon app.              
            </li>
            <li>
            Washing: Our team thoroughly washes and dries your laundry.
            </li>
            <li>
            Folding: Your items are meticulously folded, pressed and delivered back to you.
            </li>
          </ol>
      </div>
    ),
  },
  {
    id: 8,
    link: "bag-cleaning",
    title: "Bag Cleaning",
    subtitle:
      "Your bag is cleaned and neatly packed",
    icon:bagCleaningIcon,
    image:
    bagCleaningImg,
    html: (
      <div className="content-container-services content-container-service"  >
      <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Bag Cleaning Service in Dubai</h2>
      <p style={{ fontSize: 18 }}>
      Trust WashOn in Dubai for thorough bag cleaning, coupled with complimentary pickup and delivery. 
      Schedule a pickup, and our team will collect your bags. We employ specialized cleaning techniques to 
      remove dirt and stains, ensuring your bags look fresh and rejuvenated. 
      Experience convenience and quality with WashOn today.
        </p>
        <h3 style={{ fontSize: 22 }}>Leather Bag Cleaning</h3>
        <ol style={{ fontSize: 18 }}>
            <li>
            Inspection: Our experts assess the leather bag's condition, identifying any stains, scuffs, or damage.              
            </li>
            <li>
            Cleaning: Using gentle yet effective leather-safe products, we meticulously clean the bag to remove dirt and stains while preserving its texture and color.
            </li>
            <li>
            Conditioning: After cleaning, we apply a premium leather conditioner to nourish and protect the leather, restoring its suppleness and shine.
            </li>
          </ol>
      </div>
    ),
  },
  {
    id: 9,
    link: "alteration-service",
    title: "Alteration Service",
    subtitle:
      "Expert Alterations Tailored to Your Measurements for Flawless Style and Comfort.",
    icon:alterationIcon,
    image:
    alterationImg,
    html: (
      <div className="content-container-services content-container-service"  >
      <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Alteration Service in Dubai</h2>
      <p style={{ fontSize: 18 }}>
      Experience seamless alterations with WashOn in Dubai, complemented by complimentary pickup and delivery. 
      Schedule a pickup, and our skilled tailors will collect your garments. 
      They undergo precise alterations to achieve the perfect fit. 
      Your customized garments are then delivered back to you, ensuring impeccable style and comfort.
        </p>
        <h3 style={{ fontSize: 22 }}>Professional Alteration Service</h3>
        <ol style={{ fontSize: 18 }}>
            <li>
            Schedule Pickup: Arrange a convenient pickup time for your garments, free of charge.             
            </li>
            <li>
            Tailoring: Our expert tailors perform precise alterations tailored to your measurements for the perfect fit.
            </li>
            <li>
            Delivery: Your customized garments are promptly delivered back to you, ensuring impeccable style and comfort.
            </li>
          </ol>
      </div>
    ),
  },
  {
    id: 10,
    link: "stroller-cleaning",
    title: "Stroller Cleaning",
    subtitle:
      "Keep your stroller clean and fresh with our expert service. Say goodbye to stains and odors, hello to hassle-free outings!",
    icon:strollerCleaningIcon,
    image:
    strollerCleaningImg,
    html: (
      <div className="content-container-services content-container-service"  >
      <h2 style={{ fontSize: 24, justifyContent: "left", marginLeft: 0 }}>Stroller Cleaning in Dubai</h2>
      <p style={{ fontSize: 18 }}>
      WashOn offers hassle-free stroller cleaning with Free Pickup and Delivery. 
      Our thorough process eliminates dirt, stains, and bacteria, ensuring a hygienic 
      ride for your little one. Enjoy convenience and peace of mind, knowing your stroller 
      will be returned sparkling clean.
        </p>
        <h3 style={{ fontSize: 22 }}>Stroller Cleaning Process</h3>
        <ol style={{ fontSize: 18 }}>
            <li>
            Assessment: We inspect your stroller for dirt, stains, and damage.             
            </li>
            <li>
            Pre-treatment: Stubborn stains are pre-treated for effective removal.
            </li>
            <li>
            Cleaning: Using gentle yet powerful agents, we thoroughly clean all surfaces.
            </li>
            <li>
            Final Inspection: Your stroller undergoes a final check to ensure it's spotless and ready for use.
            </li>
          </ol>
      </div>
    ),
  },
];
