import React from "react";

import backgroundImg from "../images/page_background_sec_web.svg";
import backgroundMobileImg from "../images/page_background_sec_mobile2.svg";
import largeBackgroundImg from "../images/page_background_sec_web_large.svg";
import { Helmet } from 'react-helmet';


function LocationsPage() {
  const locations = [
    "Al Barari",
    "Al Furjan",
    "Al Safa",
    "Arabian Ranches",
    "Barsha",
    "Blue Waters",
    "Business Bay",
    "City Walk",
    "DIFC",
    "DIP",
    "Deira",
    "Discovery Gardens",
    "District One",
    "Downtown",
    "Dubai Hills",
    "Dubai Land",
    "Dubai Villas",
    "Emirates Hills",
    "Golden Mile",
    "Greens",
    "JBR",
    "JLT",
    "JVC",
    "JVT",
    "Jaddaf",
    "Jumeirah",
    "Jumeirah Islands",
    "Jumeirah Park",
    "MBR City",
    "Marina",
    "Meadows",
    "Media City",
    "Meydan",
    "Mirdiff",
    "Motor City",
    "Mushrif",
    "Palm Jumeirah",
    "Remraam",
    "Saadiyat",
    "Silicon Oasis",
    "Sports City",
    "Springs",
    "Sufouh",
    "Tecom",
    "The Villa",
    "Um Suqeim",
    "Wasl",
  ];

  return (
    <div>
        <Helmet>
    <title>WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Service Area </title>    
    <meta property="title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Service Areas" />
    <meta name="description" content="WashOn Laundry's Service Areas: Explore the neighborhoods and regions we proudly serve in Dubai. Find your area today!" />
    <meta name="keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning In Dubai, Shoe cleaning in Dubai, Best laundry in dubai, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Service Areas" />
    <meta property="og:description" content="WashOn Laundry's Service Areas: Explore the neighborhoods and regions we proudly serve in Dubai. Find your area today!" />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning In Dubai, Shoe cleaning in Dubai, Best laundry in dubai, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:url" content="https://www.washon.ae/locations" />
    <meta property="og:site_name" content="WashOn Laundry Service: Service Areas" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: Service Areas" />}
    <meta name="twitter:description" content="WashOn Laundry's Service Areas: Explore the neighborhoods and regions we proudly serve in Dubai. Find your area today!"/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
    </Helmet>
      <div
        className="header-image"
        style={{ position: "absolute", top: "0", zIndex: "-1" }}
      >
        <img
          alt=""
          src={
            window.innerWidth > 1440
              ? largeBackgroundImg
              : window.innerWidth > 768
              ? backgroundImg
              : backgroundMobileImg
          }
        />
      </div>
      <div>
        <div className="heading">Locations</div>
      </div>
      <div>
        <div
          className="content-container"
          style={{
            justifyContent: "left",
            margin: window.innerWidth > 768 ? "3% 6% 3% 6%" : "2% 0 2% 0",
            color: "rgb(0, 0, 0.8)",
            backgroundColor: "#F9F9FB",
            padding: window.innerWidth > 768 ? 40 : 20,
            borderRadius: 10,
          }}
        >
          <p
            style={{
              color: "rgb(143, 152, 179)",
              align: "justify",
              background: "#ffffff",
              padding: 35,
              borderRadius: 10,
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
              marginTop: 10,
              fontSize: window.innerWidth > 768 ? 18 : 14,
            }}
          >
            We operate in Dubai. Place your laundry pickup request and our
            captain will come and pick your clothes.
          </p>
          <p
            style={{
              color: "rgb(143, 152, 179)",
              align: "justify",
              background: "#ffffff",
              padding: 35,
              borderRadius: 10,
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
              marginTop: 20,
              fontSize: window.innerWidth > 768 ? 30 : 24,
              fontWeight: "bold",
            }}
          >
            Areas We Serve :
          </p>
          <div
            style={
              window.innerWidth > 768
                ? {
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            {locations.map((loc, i) => (
              <p
                key={i}
                style={{
                  color: "#64648F",
                  align: "justify",
                  background: "#ffffff",
                  padding: 35,
                  borderRadius: 10,
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
                  marginTop: 10,
                  fontSize: window.innerWidth > 768 ? 18 : 14,
                  fontWeight: "bold",
                  width:
                    window.innerWidth > 768
                      ? i % 2 === 0
                        ? "95%"
                        : "100%"
                      : "100%",
                }}
              >
                {loc}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationsPage;
