import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { services } from "../data/services";
import { schemafiles } from "../data/schemafiles";
import downloadGoogleImg from "../images/download-google-play.png";
import downloadAppleImg from "../images/download-app-store.png";
import phone1 from "../images/phone1.png";
import phone2 from "../images/phone2.png";
import downloadApp from "../images/download-app.png";
import  { Redirect } from 'react-router-dom'




const ServicePage = (props) => {
  const match = useRouteMatch();
  const slug = match.params.slug;
  const service = services.filter((a) => a.link === slug)[0];
  if(service===undefined){
    return <Redirect to='/error'  />
  }
  return (
    <div>
    <Helmet>
    (<script type="application/ld+json">{`${JSON.stringify(schemafiles)}`}</script>)
    <title>{service.title} </title>    
    <meta property="title" content={service.title} />
    <meta name="description" content={service.subtitle} />
    <meta name="keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={service.title} />
    <meta property="og:description" content={service.subtitle} />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Laundry in Motor City, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:url" content={service.link} />
    <meta property="og:site_name" content="WashOn Laundry Service: Blogs" />
    <meta property="og:image" content={service.image}  />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={service.title} />
    <meta name="twitter:description" content={service.subtitle} />
    <meta name="twitter:image" content={service.image}/>
        {/* Add other head content specific to the about page */}
        </Helmet>
    <div
      className="content-container"
      style={{
        justifyContent: "left",
        margin: window.innerWidth > 768 ? "3% 6% 3% 6%" : "2% 0 2% 0",
        color: "rgb(0, 0, 0.8)",
        backgroundColor: "#FFFFFF",
        padding: window.innerWidth > 768 ? 40 : 20,
        borderRadius: 10,
      }}
    >
      <h1 style={{ marginBottom: 20, fontSize: 35, color: "rgb(30, 30, 93)", justifyContent: "left", display: "flex" }}>
        {service.title}
      </h1>
      <div
        style={{
          width: "100%",
          justifyContent: "left",
          display: "flex",
          margin: "10px 0 10px 0",
        }}
      >
        <img
          src={service.image}
          alt={service.title}
          style={{
            width: "80%",
          }}
        />
      </div>

      {service.html}
    <div>
    <div className="download-app">
            <div className="download-text">
              <h3>Download WashOn APP!</h3>
              <p>
                Download our app to book and manage your appointments with a few
                taps, view your profile and see real time status of your
                bookings.{" "}
              </p>
              <div style={window.innerWidth < 768 ? { display: "flex" } : {}}>
                <a href="http://onelink.to/p3c5wk">
                  <img  alt="WashOn Laundry: Play Store" 
                    style={{ marginRight: "10px" }}
                    src={downloadGoogleImg}
                  />
                </a>
                <a href="http://onelink.to/p3c5wk">
                  <img alt="WashOn Laundry: App Store" src={downloadAppleImg} />
                </a>
              </div>
            </div>
            {window.innerWidth < 768 && <img alt="WashOn Laundry: Download App" src={downloadApp} />}
            {window.innerWidth > 768 && (
              <img
                alt="WashOn Laundry: WashOn App"
                style={{ position: "absolute", top: "-3%", right: "26%" }}
                src={phone1}
              />
            )}
            {window.innerWidth > 768 && (
              <img
                style={{ position: "absolute", top: "3%", right: "13%" }}
                alt="WashOn Laundry: App"
                src={phone2}
              />
            )}
    </div>
      </div>
      </div>
    </div>
  );
};

export default ServicePage;
